import Vue from 'vue'

export const eventBus = new Vue()

export const busEvents = {
  LOGIN_FAILED: 'login-failed',
  LOGIN_NOT_SUPERADMIN: 'login-no-super-admin',
  APP_ERROR: 'app-error',
  APP_NOTIFICATION: 'app-notification',
}
