<template>
  <v-app>
    <app-bar />
    <left-navigation-menu />
    <page-container />
    <app-notification />
    <app-loading-overlay
      v-if="loading"
    />
  </v-app>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { eventBus, busEvents } from '@/EventBus'

  export default {
    name: 'AppContainer',

    components: {
      AppBar: () => import('./components/core/AppBar'),
      LeftNavigationMenu: () => import('./components/core/LeftNavigationMenu'),
      PageContainer: () => import('./components/core/View'),
      AppLoadingOverlay: () => import('./component/LoadingOverlay'),
      AppNotification: () => import('./components/core/AppNotification'),
    },
    computed: {
      ...mapGetters({
        loading: 'app/LOADING',
      }),
    },
    mounted () {
      // User is logged in, lets bootstrap data
      this.$careConnect.getAgencies()
        .then(async (resp) => {
          this.updateAgencies(resp.agencies)
        })
        .catch((err) => {
          const text = err.json ? err.json.error : 'Failed to load agencies'
          eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
        })
        .finally(() => {
        })
      this.$careConnect.getInternalAdmins()
        .then(resp => {
          this.setInternalAdmins(resp)
        })
        .catch(err => {
          const text = err.json ? err.json.error : 'Failed to load internal admins'
          eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
        updateAgencies: 'agency/UPDATE_AGENCIES',
        addAgency: 'agency/ADD_AGENCY',
        updateAgency: 'agency/UPDATE_AGENCY',
        setInternalAdmins: 'internaladmin/SET_INTERNAL_ADMINS',
      }),
    },
  }
</script>
